import Vue from "vue";
import store from '@/store';
import translationsJSON from "@/translations.json";

export const translateLang = function(key){
  const lang = store.state.layout.language;

  const translation = translationsJSON[key]?.[lang] || key;
  if (translation === key){
    console.log('No translation for key: '+ key);
  }
  return translation;
};

export const translatePlugin = {
  install(){
    Vue.prototype.$translate = function(key){

      const lang = store.state.layout.language;

      const translation = translationsJSON[key]?.[lang] || key;
      if (translation === key){
        console.log(('No translation for key: '+ key));
      }
      return translation;
    };
  },
};

export function getQueryLang(langList) {

  const supportedLanguages = Object.values(langList);
  const queryLang = new URLSearchParams(window.location.search)?.get('lang');

  return supportedLanguages.includes(queryLang) ? queryLang : null;
}