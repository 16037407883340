import baseApi from "@/helpers/baseApi";
import { setAuthToken } from "@/helpers/auth.js";
import { translateLang } from "@/plugins/i18n.js";

export async function authViaBridge(data) {
  try {
    delete baseApi.defaults.headers['Authorization'];

    let res = await baseApi.post('api/v2/user/create/',
      data,
    );
    console.log(res);

    if (res?.data?.auth_token){
      setAuthToken(res.data.auth_token);
      return res.data.auth_token;
    } else {
      // TODO report event via sentry
      throw Error(translateLang('app-loader_error_description_no-post-token'));
    }
  } catch (err){
    console.log('Ree Response', err?.response);
    return addAuthError(err);
  }
}

async function addAuthError(error){
  let errorCode = error?.response?.status;

  const finalErrorText = errorCode
    ? errorCode + ': ' + (error?.response?.data?.detail || error)
    : error?.response?.data?.detail || error;

  throw Error(finalErrorText);
}