<template>
  <div class="market-loader-bg">
    <div class="market-loader">
      <div class="market-icon-container">
        <img
          alt="cart"
          height="100%"
          src="@/assets/marketplace/cart_icon.svg"
          width="100%"
        >
      </div>
      <div style="width: 250px">
        <VProgressLinear
          v-if="loadingText"
          color="#EF7F1A"
          indeterminate
        />
      </div>
      <div
        v-if="loadingText"
        class="loading-text"
      >
        {{ loadingText }}
      </div>
      <div
        v-if="loginError"
        class="loading-error"
      >
        Ошибка авторизации: {{ loginError }}
      </div>
      <div
        v-if="categoryError"
        class="loading-error"
      >
        Ошибка загрузки категорий: {{ categoryError }}
      </div>
      <div
        v-if="cartError"
        class="loading-error"
      >
        Ошибка загрузки корзины: {{ cartError }}
      </div>
      <div
        v-if="orderError"
        class="loading-error"
      >
        Ошибка загрузки заказов: {{ orderError }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions,mapState } from 'vuex';

export default {
  data: () => ({
    loadingText: "",
  }),
  computed: {
    ...mapState("merch", [
      "loginError",
      "categoryError",
      "cartError",
      "orderError",
    ]),
  },
  async created() {
    this.loadingText = "Получаем данные пользователя...";
    await this.getUserInfo();
    this.loadingText = "Получаем список товаров...";
    await this.getCategories();
    this.loadingText = "Получаем данные корзины...";
    await this.getUserCart();
    this.loadingText = "Получаем данные заказов...";
    await this.getUserOrders();
    this.loadingText = "Получаем список статусов...";
    await this.getOrderStatuses();
    this.loadingText = "";
    this.emitHideMarketLoader();
  },
  methods: {
    ...mapActions("merch", [
      "getUserInfo",
      "getOrderStatuses",
      "getUserCart",
      "getUserOrders",
      "getCategories",
    ]),
    emitHideMarketLoader() {
      this.$emit("hideMarketLoader");
    },
  },
};
</script>

<style lang="scss" scoped>
.market-loader-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
}
.market-loader {
  margin-top: 100px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.market-icon-container {
  margin-bottom: 20px;
  height: 100px;
}
.loading-text {
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: center;
}
.loading-error {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
}
</style>
