const state = () => ({
  items: [],
});

const mutations = {

  'add_item'(state, payload){
    let itemIndex = state.items.findIndex((foodItem) => foodItem.info.uid === payload.uid);
    if (itemIndex >= 0){
      // increment existing item
      let currentQty = state.items[itemIndex].qty;
      console.log(currentQty);
      state.items = [
        ...state.items.filter((i, k) => k !== itemIndex),
        {
          info: payload,
          qty: ++currentQty,
        },
      ];
    } else {
      // add new item
      state.items = [
        ...state.items,
        {
          info: payload,
          qty: 1,
        },
      ];
    }
  },

  'remove_item'(state, payload){
    let itemIndex = state.items.findIndex((foodItem) => foodItem.info.uid === payload.uid);
    if(itemIndex<0) throw Error(`Не найден элемент ${payload.uid}`);
    let currentQty = state.items[itemIndex].qty;
    if (currentQty > 1){
      // decrease item qty
      state.items = [
        ...state.items.filter((i, k) => k !== itemIndex),
        {
          info: payload,
          qty: --currentQty,
        },
      ];
    } else {
      // remove item
      state.items = state.items.filter((i, k) => k !== itemIndex);
    }
  },

  'clear'(state){
    state.items = [];
  },

};

const actions = {
  addItem({ commit }, payload){
    commit('add_item', payload);
  },

  removeItem({ commit }, payload){
    commit('remove_item', payload);
  },
};

const getters = {

  'price_total': (state) => {
    return state.items.reduce((acc, cur) => {
      return acc + (cur.info.price * cur.qty);
    }, 0);
  },

  'qty_total': (state) => {
    return state.items.reduce((acc, cur) => {
      return acc + cur.qty;
    }, 0);
  },

  'qty_by_uid': (state) => (uid) => {
    return state.items.find(item => item.info.uid === uid)?.qty || 0;
  },
};

export const food = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};