const state = () => ({
  showReplyInputs: false,
  reply_id: undefined,
  reply_name: undefined,
  comments: [],
  drpCategories: [],
  drpCompanies: [],
});

const mutations = {
  'set_show_reply_input': (state, payload) => {
    state.showReplyInputs = payload;
  },
  'set_reply_user': (state, payload) => {
    state.reply_id = payload.id;
    state.reply_name = payload.name;
  },
  'set_comments': (state, payload) => {
    state.comments = payload;
  },
  'set_drp_categories': (state, payload) => {
    state.drpCategories = payload;
  },
  'set_drp_companies': (state, payload) => {
    state.drpCompanies = payload;
  },
  // 'set_reply_model': (state, model, model_id) => {
  //   state.reply_model = model;
  //   state.reply_model_id = model_id;
  // },
};

const actions = {

  showReplyInputs({ commit }) {
    commit('set_show_reply_input', true);
  },

  hideReplyInputs({ commit }) {
    commit('set_show_reply_input', false);
  },

  setReplyUser({ commit }, { id, name }) {
    commit('set_reply_user', { id, name });
  },

  clearReplyUser({ commit }) {
    commit('set_reply_user', {
      id: undefined,
      name: undefined,
    });
  },

  setComments({ commit }, comments) {
    commit('set_comments', comments);
  },

  addComment({ commit, state }, comment) {
    commit('set_comments', [
      ...state.comments,
      comment,
    ]);
  },

  clearComments({ commit }) {
    commit('set_comments', []);
  },

  setDrpCategories({ commit }, categories) {
    commit('set_drp_categories', categories);
  },

  setDrpCompanies({ commit }, companies) {
    commit('set_drp_companies', companies);
  },
};

export const way = {
  namespaced: true,
  state,
  mutations,
  actions,
};