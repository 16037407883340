import axios from "axios";
import store from "@/store/index";

const merchApiInstance = axios.create({
  baseURL: (process.env.VUE_APP_MERCH_HOST || ''),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

merchApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // const { response, request } = error;
    // if ((!response && request) || response.status === 500) {
    //   window.location.href = '/s/under_maintenance';
    // }
    if (error.response?.status === 401 && !error.config._isRetry) {
      error.config._isRetry = true;
      error.config._retry = await store.dispatch('merch/getUserInfo');
      error.config.headers['Merchauthorization'] = merchApiInstance.defaults.headers['Merchauthorization'];
      return merchApiInstance(error.config);
    }
    return Promise.reject(error);
  },
);

export default merchApiInstance;
export { merchApiInstance as merchApi };
