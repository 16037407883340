import axios from "axios";

const wayApiInstance = axios.create({
  baseURL: (process.env.VUE_APP_ERGWAY_HOST || ''),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  // params: {
  //   format: 'json'
  // }
});

export default wayApiInstance;
export { wayApiInstance as wayApi };