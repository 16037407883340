import { render, staticRenderFns } from "./market_loader.vue?vue&type=template&id=8179e248&scoped=true"
import script from "./market_loader.vue?vue&type=script&lang=js"
export * from "./market_loader.vue?vue&type=script&lang=js"
import style0 from "./market_loader.vue?vue&type=style&index=0&id=8179e248&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8179e248",
  null
  
)

export default component.exports