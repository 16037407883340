<template>
  <div
    style="height: 100%;"
  >
    <AppLoaderScreen
      v-if="!menu_is_requested && !is_select_auth_form"
      :theme="theme"
      @authenticated="handleLoggedIn"
      @on410="navigateToRegForm"
    />
    <MarketLoaderScreen
      v-else-if="isMarket && isMarketLoaderVisible"
      @hideMarketLoader="isMarketLoaderVisible = false"
    />
    <MerchLoaderScreen
      v-else-if="isMerch && isMerchLoaderVisible"
      @hideMarketLoader="isMerchLoaderVisible = false"
    />
    <AppLayout
      v-else
      :hide-navs="is_select_auth_form && !menu_is_requested"
    >
      <template
        v-slot:header
      >
        <RouterView
          name="header"
        />
      </template>
      <template v-slot:main>
        <RouterView />
        <!-- <AppOnboarding
          v-if="is_onboarding && (!is_select_auth_form && menu_is_requested)"
          v-model="is_onboarding"
        /> -->
      </template>
      <template
        v-slot:footer
      >
        <RouterView
          name="footer"
        />
        <FloatingNav
          v-if="showFloatingNav"
        />
      </template>
    </AppLayout>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AppLayout from "@/components/App/AppLayout";
import AppLoaderScreen from "@/components/App/AppLoaderScreen.vue";
// import AppOnboarding from "@/components/App/AppOnboarding";
import FloatingNav from "@/components/FloatingNav";
import MarketLoaderScreen from "@/components/marketplace/market_loader.vue";
import MerchLoaderScreen from "@/components/merch/merch_loader.vue";
import app from '@/helpers/app';
import baseApi from "@/helpers/baseApi";
import { setTabActiveHandler } from '@/helpers/bridge';
import { MAIN_PAGE, NOTIFICATIONS_PAGE } from "@/config/constants";

export default {
  name: "APP",
  components: {
    FloatingNav,
    AppLayout,
    AppLoaderScreen,
    MarketLoaderScreen,
    MerchLoaderScreen,
    // AppOnboarding,
  },
  data(){
    return {
      header: {
        title: 'Smart ERG',
        back: false,
      },
      showFloatingNav: process.env.VUE_APP_SHOW_FLOATING_MENU === 'true',
      theme: new URLSearchParams(window.location.search).get('theme'),
      isMarketLoaderVisible: true,
      isMerchLoaderVisible: true,
    };
  },
  methods: {
    tabActiveHandler(){
      if(this.$route.name !== MAIN_PAGE){
        this.$router.replace({
          name: 'MainPage',
        });
      }
    },
    handleLoggedIn(){
      this.$store.dispatch('mainPage/initialize');
    },
    navigateToRegForm(payload){ // on 410
      this.$store.dispatch('layout/setIsSelectAuthForm', true);
      this.$router.replace({
        name: 'FormPage',
        params: {
          form_data: payload,
        },
      });
    },

    headerMenuItemClickHandler(id){
      let { dispatch } = this.$store;
      if (id === 'reload'){
        location.reload();
      } else if (id === 'notifications'){
        if(this.$route.name === NOTIFICATIONS_PAGE)return;
        dispatch('layout/hideBurgerContent');
        this.$router.replace({ name: 'NotificationsPage' });
      } else {
        dispatch('layout/alert', 'Неизвестный эллемент меню: ' + id);
      }
    },
  },
  computed: {
    // is_onboarding: {
    //   get(){
    //     return this.$store.state.mainPage.is_onboarding;
    //   },
    //   set(){
    //     this.$store.dispatch('mainPage/setOnboarded');
    //   },
    // },
    ...mapGetters({
      unread_total: 'notifications/unread_total',
      notifications_requested: 'notifications/notifications_requested',
      menu_is_requested: 'mainPage/menu_is_requested',
    }),
    ...mapState("layout", [
      "language",
      "is_select_auth_form",
      "header_title",
    ]),
    show_header_menu_items(){
      return !this.is_select_auth_form && this.menu_is_requested && this.notifications_requested && !this.is_onboarding;
    },
    isMarket() {
      return this.$route.path.startsWith('/s/market');
    },
    isMerch() {
      return this.$route.path.startsWith('/s/merch');
    },
  },

  watch: {

    "$store.state.layout.theme_color": (newColor, oldColor) => {
      if (newColor !== oldColor){
        document.querySelector('meta[name="theme-color"]').setAttribute('content',  newColor);
      }
    },

    menu_is_requested(val){
      if (val){
        app.backNavigateQueue.init();
        setTabActiveHandler(this.tabActiveHandler.bind(this));
      }
    },

    show_header_menu_items(val){
      if (val){
        app.initHeaderMenuItems({
          notifications_count: this.unread_total,
          handler: this.headerMenuItemClickHandler,
        });
      }
    },
    language: {
      immediate: true,
      handler(val){
        baseApi.defaults.headers['Accept-Language'] = val;
      },
    },
  },

};
</script>
<style lang="scss" specific>
@import "node_modules/normalize.css/normalize";
@import "src/styles/index.scss";
</style>
