import axios from "axios";

const myApiInstance = axios.create({
  baseURL: (process.env.VUE_APP_MY_HOST || ''),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Platform': 'mobile',
  },
});

export default myApiInstance;
export { myApiInstance as myApi };