import axios from "axios";


const myVideoApiInstance = axios.create({
    baseURL: (process.env.VUE_APP_MYVIDEO_HOST || ''),
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});

export default myVideoApiInstance;
export { myVideoApiInstance as myVideoApi };