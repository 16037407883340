import Vue from 'vue';
import Vuex from 'vuex';

import { food } from './food.module';
import { form } from './form.module';
import { layout } from './layout.module';
import { mainPage } from './mainPage.module';
import { marketplace } from './marketplace.module';
import { merch } from './merch.module';
import { notifications } from './notifications.module';
import { pdf } from './pdf.module';
import { user } from './user.module';
import { way } from './way.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    food,
    form,
    layout,
    mainPage,
    notifications,
    pdf,
    user,
    way,
    marketplace,
    merch,
  },
});
