import marketplaceApi from "@/helpers/marketplaceApi";

const state = () => ({
  api_url: process.env.VUE_APP_MARKETPLACE_HOST,
  marketUser: undefined,
  marketToken: '',
  limit: '',
  stationeryLimit: '',
  metalRollLimit: '',
  limitType: 'tools',
  addressList: [],
  mvzList: [],
  permsList: [],
  city: null,
  stories: [],
  statuses: undefined,
  cart: [],
  cart_products: [],
  delegates: [],
  cancelQueue: [],
  ordersPaginationInfo: undefined,
  orders: [],
  orderById: undefined,
  approveOrdersPaginationInfo: undefined,
  approveOrders: [],
  approveOrder: undefined,
  acceptOrdersPaginationInfo: undefined,
  acceptOrders: [],
  acceptOrdersAlert: false,
  acceptOrder: undefined,
  categories: [],
  hasCertificate: undefined,

  listOfProducts: [],
  product: undefined,
  productReviews: [],
  countOfProducts: 0,
  searchResult: [],
  countOfSearchResults: 0,
  isSearching: false,
  priceSorting: "",
  productFilters: {
    brand: null,
    comments: null,
    isAvailable: null,
    range: [ 0, 1 ],
  },
  priceRange: [ 0, 1 ],
  brandsList: [],
  nextProducts: '',

  wishlistProducts: undefined,

  loginError: undefined,
  cartError: undefined,
  delegateError: undefined,
  orderError: undefined,
  categoryError: undefined,
});

const mutations = {

  // ERRORS
  setLoginError(state, payload) {
    state.loginError = payload;
  },
  setCartError(state, payload) {
    state.cartError = payload;
  },
  setDelegatesError(state, payload) {
    state.delegateError = payload;
  },
  setOrderError(state, payload) {
    state.orderError = payload;
  },
  setCategoryError(state, payload) {
    state.categoryError = payload;
  },
  // ERRORS ^

  setCity(state, payload) {
    state.city = payload;
  },

  setUserInfo(state, payload) {
    state.marketUser = payload;
    state.marketToken = payload.token;
    state.hasCertificate = payload.has_certificate;
    if (payload.order_limit) {
      state.limit = parseFloat(payload.order_limit);
    } else state.limit = '';
    if (payload.stationery_limit) {
      state.stationeryLimit = parseFloat(payload.stationery_limit);
    } else state.stationeryLimit = '';
    if (payload.metal_roll_limit) {
      state.metalRollLimit = parseFloat(payload.metal_roll_limit);
    } else state.metalRollLimit = '';
    state.addressList = payload.addresses;
    state.mvzList = payload.mvz;
    state.permsList = payload.perms;
  },

  setOrderStatuses(state, payload) {
    state.statuses = payload;
  },

  setUserCart(state, payload) {
    state.cart = payload;
    state.cart_products = (payload?.lines || []).reduce((acc, item) => {
      return [...acc, ...item.products];
    }, []);
  },

  setDelegates(state, payload) {
    state.delegates = payload;
  },

  setOrdersPaginationInfo(state, payload) {
    state.ordersPaginationInfo = payload;
  },

  setUserOrders(state, payload) {
    state.orders = state.orders.concat(payload);
  },

  'clear-user-orders'(state) {
    state.ordersPaginationInfo = undefined;
    state.orders = [];
  },

  setOrderById(state, payload) {
    state.orderById = payload;
  },

  'clear-order-by-id'(state) {
    state.orderById = undefined;
  },

  setApproveOrdersPaginationInfo(state, payload) {
    state.approveOrdersPaginationInfo = payload;
  },

  setApproveOrders(state, payload) {
    state.approveOrders = state.approveOrders.concat(payload);
  },

  'clear-approve-orders'(state) {
    state.approveOrdersPaginationInfo = undefined;
    state.approveOrders = [];
  },

  setApproveOrder(state, payload) {
    state.approveOrder = payload;
  },

  'clear-approve-order'(state) {
    state.approveOrder = undefined;
  },

  setAcceptOrdersPaginationInfo(state, payload) {
    state.acceptOrdersPaginationInfo = payload;
  },

  setAcceptOrders(state, payload) {
    state.acceptOrders = state.acceptOrders.concat(payload);
  },

  'clear-accept-orders'(state) {
    state.acceptOrdersPaginationInfo = undefined;
    state.acceptOrders = [];
  },

  'set-accept-orders-alert'(state, payload) {
    state.acceptOrdersAlert = payload;
  },

  setAcceptOrder(state, payload) {
    state.acceptOrder = payload;
  },

  setStories(state, payload) {
    state.stories = payload;
  },

  'clear-accept-order'(state) {
    state.acceptOrder = undefined;
  },

  setCategories(state, payload) {
    state.categories = payload;
  },

  setLimitType(state, payload) {
    state.limitType = payload;
  },

  setProduct(state, payload) {
    state.product = payload;
  },

  'clear-product'(state) {
    state.product = undefined;
  },

  setProductReviews(state, payload) {
    state.productReviews = payload;
  },

  'clear-product-reviews'(state) {
    state.productReviews = [];
  },

  setSearchResult(state, payload) {
    state.searchResult = payload;
  },

  setIsSearching(state, payload) {
    state.isSearching = payload;
  },

  setPriceSorting(state, payload) {
    state.priceSorting = payload;
  },

  setPriceRange(state, payload) {
    state.priceRange = [Math.floor(Number(payload[0])), Math.ceil(Number(payload[1]))];
    state.productFilters.range = [Math.floor(state.priceRange[0]), Math.ceil(state.priceRange[1])];
  },

  setCountOfProducts(state, payload) {
    state.countOfProducts = payload;
  },

  setCountOfSearchResults(state, payload) {
    state.countOfSearchResults = payload;
  },

  addProductFilter(state, payload) {
    for (let key in payload) {
      if (Object.keys(state.productFilters).includes(key)) {
        state.productFilters[key] = payload[key];
      }
    }
  },

  removeProductFilter(state, payload) {
    if (Object.keys(state.productFilters).includes(payload)) {
      if (payload !== 'range') state.productFilters[payload] = null;
      else state.productFilters[payload] = state.priceRange;
    }
  },

  clearFilters(state) {
    state.productFilters = {
      brand: null,
      comments: null,
      isAvailable: null,
      range: [ Math.floor(state.priceRange[0]), Math.ceil(state.priceRange[1]) ],
    };
  },

  'clear-search-result'(state) {
    state.searchResult = [];
  },

  setBrandsList(state, payload) {
    state.brandsList = payload;
  },

  setProductsList(state, payload) {
    state.listOfProducts = payload;
  },

  setNextProducts(state, payload) {
    state.nextProducts = payload;
  },

  addNextProductsToList(state, payload) {
    if(state.isSearching) {
      state.searchResult = [...state.searchResult, ...payload];
    } else {
      state.listOfProducts = [...state.listOfProducts, ...payload];
    }
  },

  setWishlistProducts(state, payload) {
    if (payload.status === 'getList') {
      state.wishlistProducts = payload.data;
    } else if (payload.status === 200) {
      state.wishlistProducts.push(payload.data);
    } else if (payload.status === 204) {
      state.wishlistProducts = state.wishlistProducts.filter(product => product.id !== payload.data.id);
    }
  },

  'clear-wishlist-products'(state) {
    state.wishlistProducts = undefined;
  },

  'add-to-cancel-queue'(state, { is_editing, lineId }) {
    if (is_editing) {
      let cancelingItem = state.cancelQueue.find(line => {
        return line === lineId;
      });
      if (cancelingItem) {
        state.cancelQueue = state.cancelQueue.filter(line => {
          return cancelingItem !== line;
        });
      } else {
        state.cancelQueue = [
          ...state.cancelQueue,
          lineId,
        ];
      }
    }
  },

  'add-all-to-cancel-queue'(state, { is_editing, lineIds }) {
    if (is_editing) {
      state.cancelQueue = lineIds;
    }
  },

  'clear-cancel-queue'(state) {
    state.cancelQueue = [];
  },
};

const actions = {

  getUserInfo({ commit }) {
    if (marketplaceApi.defaults.headers['Marketauthorization']) {
      delete marketplaceApi.defaults.headers['Marketauthorization'];
    }
    return marketplaceApi.get('/api/myerglogin/').then((res) => {
      if (res?.data) {
        commit('setUserInfo', res.data);
        commit('setCity', res.data?.city);
        marketplaceApi.defaults.headers['Marketauthorization'] = ' Markettoken ' + res.data.token;
        console.log(marketplaceApi.defaults.headers);
      } else {
        throw Error('no user info in response');
      }
    });
  },

  getOrderStatuses({ commit }) {
    return marketplaceApi.get('/api/statusmap/').then((res) => {
      if (res?.data) {
        commit('setOrderStatuses', res.data);
      } else {
        throw Error('no user info in response');
      }
    });
  },

  getUserCart({ commit }) {
    return marketplaceApi.get('/api/basket/').then((res) => {
      if (res?.data) {
        commit('setUserCart', res.data);
      } else {
        throw Error('no user info in response');
      }
    }).catch((error) => {
      if (error) {
        commit('setCartError', error);
      }
    });
  },

  getStories({ commit }) {
    // get stories from backend
    let stories = [
      {
        bg: 'stationery.png',
        story: 'stationery_story.png',
      },
      {
        bg: 'delivery_notion.png',
        story: 'delivery_notion_story.png',
      },
      {
        bg: 'order_approve.png',
        story: 'order_approve_story.png',
      },
      {
        bg: 'limit_statement.png',
        story: 'limit_statement_story.png',
      },
      {
        bg: 'application.png',
        story: 'application_story.png',
      },
      {
        bg: 'repeat_order.png',
        story: 'repeat_order_story.png',
      },
    ];
    stories = stories.map((obj, index) => {
      return { ...obj, id: index };
    });
    commit('setStories', stories);
  },

  getDelegates({ commit }) {
    return marketplaceApi.get('/api/delegateorder/users/').then((res) => {
      if (res?.data) {
        commit('setDelegates', res.data);
      } else {
        throw Error('no user info in response');
      }
    }).catch((error) => {
      if (error) {
        commit('setDelegatesError', error);
      }
    });
  },

  setDelegate({}, { orderId, userId }) { // eslint-disable-line
    return marketplaceApi.patch(`/api/delegateorder/${orderId}/`, { "user_id": userId }).then((res) => {
      if (res?.data) {
        return res.data;
      } else {
        throw Error('no user info in response');
      }
    });
  },

  getUserOrders({ state, commit }) {
    let URL = '/api/orders/';
    let page = state.ordersPaginationInfo ? `?page=${state.ordersPaginationInfo.next.split('?page=')[1]}` : '';
    return marketplaceApi.get(`${URL}${page}`).then((res) => {
      if (res?.data) {
        commit('setOrdersPaginationInfo', res.data);
        commit('setUserOrders', res.data.results);
      } else {
        throw Error('no user info in response');
      }
    }).catch((error) => {
      if (error) {
        commit('setOrderError', error);
      }
    });
  },

  clearUserOrders({ commit }) {
    commit('clear-user-orders');
  },

  getOrderById({ commit }, orderId) {
    return marketplaceApi.get(`/api/orders/${orderId}/`).then((res) => {
      if (res?.data) {
        commit('setOrderById', res.data);
      } else {
        throw Error('no user info in response');
      }
    });
  },

  // eslint-disable-next-line no-unused-vars
  getLineTracking({ commit }, lineId) {
    return marketplaceApi.get(`/api/orderlines/${lineId}`).then((res) => {
      if (res?.data) {
        return res.data;
      } else {
        throw Error('no user info in response');
      }
    });
  },

  clearOrderById({ commit }) {
    commit('clear-order-by-id');
  },

  getApproveOrders({ state, commit }) {
    let URL = '/api/approves/';
    let page = state.approveOrdersPaginationInfo? `?page=${state.approveOrdersPaginationInfo.next.split('?page=')[1]}` : '';
    return marketplaceApi.get(`${URL}${page}`).then((res) => {
      if (res?.data) {
        commit('setApproveOrdersPaginationInfo', res.data);
        commit('setApproveOrders', res.data.results);
      } else {
        throw Error('no user info in response');
      }
    });
  },

  clearApproveOrders({ commit }) {
    commit('clear-approve-orders');
  },

  getApproveOrder({ commit }, orderId) {
    return marketplaceApi.get(`/api/approves/${orderId}/`).then((res) => {
      if (res?.data) {
        commit('setApproveOrder', res.data);
      } else {
        throw Error('no user info in response');
      }
    });
  },

  clearPriceRange({ commit }) {
    commit('setPriceRange', [0, 1]);
  },

  clearApproveOrder({ commit }) {
    commit('clear-approve-order');
  },

  getAcceptOrders({ state, commit }) {
    let URL = '/api/acceptorders/';
    let page = state.acceptOrdersPaginationInfo?.next ? `?page=${state.acceptOrdersPaginationInfo.next.split('?page=')[1]}` : '';
    return marketplaceApi.get(`${URL}${page}`).then((res) => {
      if (res?.data) {
        commit('setAcceptOrdersPaginationInfo', res.data);
        commit('setAcceptOrders', res.data.results);
        return res.data;
      } else {
        throw Error('no user info in response');
      }
    });
  },

  clearAcceptOrders({ commit }) {
    commit('clear-accept-orders');
  },

  setAcceptOrdersAlert({ commit }, value) {
    commit('set-accept-orders-alert', value);
  },

  getAcceptOrder({ commit }, orderNumber) {
    return marketplaceApi.get(`/api/acceptorder/${orderNumber}/`).then((res) => {
      if (res?.data) {
        commit('setAcceptOrder', res.data);
        return res.data;
      } else {
        throw Error('no user info in response');
      }
    });
  },

  clearAcceptOrder({ commit }) {
    commit('clear-accept-order');
  },

  acceptOrderedLines({}, { orderNumber, linesArray }) { // eslint-disable-line
    return marketplaceApi.patch(`/api/acceptorder/${orderNumber}/`, { "accepted_lines": linesArray });
  },

  rejectOrderedLines({}, { orderNumber, linesArray }) { // eslint-disable-line
    return marketplaceApi.patch(`/api/acceptorder/${orderNumber}/`, { "rejected_lines": linesArray });
  },

  approveOrDeclineOrder({}, { orderId, approve, comment }) { // eslint-disable-line
    let payload = { approve, comment };
    return marketplaceApi.patch(`/api/approves/${orderId}/`, payload);
  },

  getCategories({ commit }, payload = 'tools') {
    return marketplaceApi.get('/api/categories/', {
      params: {
        product_class: payload,
      },
    }).then((res) => {
      if (res?.data) {
        commit('setCategories', res.data);
        commit('setLimitType', payload);
      } else {
        throw Error('no user info in response');
      }
    }).catch((error) => {
      if (error) {
        commit('setCategoryError', error);
      }
    });
  },

  getSubcategoryById({ commit }, payload) {
    return marketplaceApi.get(`/api/categories/${payload}/`).then((res) => {
      if (res?.data) {
        commit('setCategories', res.data);
      } else {
        throw Error('no user info in response');
      }
    });
  },

  getProductsList({ commit, state }, categoryId) { // eslint-disable-line
    let URL = `/api/categories/${categoryId}/products/`;
    let filters = `?order_by=${state.priceSorting}&is_available=${state.productFilters.isAvailable}&with_reviews=${state.productFilters.comments}`;
    let priceFilter = '';
    if(state.productFilters.range[0] > 0 || state.productFilters.range[1] > 1) {
      priceFilter = `&price_min=${state.productFilters.range[0]}&price_max=${state.productFilters.range[1]}`;
    }
    return marketplaceApi.get(`${URL}${filters}${priceFilter}`).then((res) => {
      if(res?.data) {
        commit('setProductsList', res.data.results);
        commit('setCountOfProducts', res.data.count);
        if(state.priceRange[0] == 0  && state.priceRange[1] == 1) {
          commit('setPriceRange', [res.data.min_price, res.data.max_price]);
        }
        commit('setNextProducts', res.data.next);
      } else {
        throw Error('no user info in response');
      }
    });
  },

  getNextProducts({ commit, state }) {
    if(state.nextProducts) {
      return marketplaceApi.get(state.nextProducts).then((res) => {
        if(res?.data) {
          commit('addNextProductsToList', res.data.results);
          commit('setNextProducts', res.data.next);
        } else {
          throw Error('no products');
        }
      });
    }
  },

  clearWishlistProducts({ commit }) {
    commit('clear-wishlist-products');
  },

  getProduct({ commit }, payload) {
    return marketplaceApi.get(`/api/products/${payload}/`).then((res) => {
      if (res?.data) {
        commit('setProduct', res.data);
        commit('setLimitType', res.data?.product_class ?? 'tools');
        let _products = JSON.parse(localStorage.getItem('viewed-items') || "[]");

        _products = _products.filter(p => p.id !== res?.data.id);
        _products = [{
          type: res.data?.product_class,
          title: res?.data.title,
          id: res?.data.id,
          image: res?.data?.images?.[0] ? `${res?.data?.images?.[0]}` : '',
          rating: res?.data.rating,
          price: res?.data?.price,
          is_in_wishlist: res?.data.is_in_wishlist || false,
          availability: res?.data.availability || {},
        }, ..._products];
        if(_products.length > 4) _products.pop();
        localStorage.setItem('viewed-items', JSON.stringify(_products));
      } else {
        throw Error('no user info in response');
      }
    });
  },

  getWishlistProducts({ commit }) {
    return marketplaceApi.get('/api/wishlist/').then((res) => {
      if (res?.data) {
        let payload = {
          data: res.data.lines.map(line => line.product),
          status: 'getList',
        };
        commit('setWishlistProducts', payload);
      } else {
        throw Error('Cannot get wishlist');
      }
    });
  },

  addToWishlist({ commit }, product) {
    return marketplaceApi.post('/api/wishlist/', { product_id: product.id }).then((res) => {
      if(res?.status === 200 || res?.status === 204) {
        let payload = {
          data: product,
          status: res.status,
        };
        commit('setWishlistProducts', payload);
      } else {
        throw Error('Cannot change wishlist status of product');
      }
    });
  },

  clearProduct({ commit }) {
    commit('clear-product');
  },

  getProductReviews({ commit }, payload) {
    return marketplaceApi.get(`/api/products/${payload}/reviews/`).then((res) => {
      if (res?.data) {
        commit('setProductReviews', res.data);
      } else {
        throw Error('no user info in response');
      }
    });
  },

  clearProductReviews({ commit }) {
    commit('clear-product-reviews');
  },

  searchForProduct({ commit, state }, payload) {
    let URL = `/api/products/search/${payload}/`;
    let filters = `?order_by=${state.priceSorting}&is_available=${state.productFilters.isAvailable}&with_reviews=${state.productFilters.comments}`;
    let priceFilter = '';
    if(state.productFilters.range[0] > 0 || state.productFilters.range[1] > 1) {
      priceFilter = `&price_min=${state.productFilters.range[0]}&price_max=${state.productFilters.range[1]}`;
    }
    return marketplaceApi.get(`${URL}${filters}${priceFilter}`).then((res) => {
      if (res?.data) {
        commit('setSearchResult', res.data.results);
        commit('setCountOfSearchResults', res.data.count);
        if(state.priceRange[0] == 0  && state.priceRange[1] == 1) {
          commit('setPriceRange', [res.data.min_price, res.data.max_price]);
        }
        commit('setNextProducts', res.data.next);
      } else {
        throw Error('no user info in response');
      }
    });
  },

  isActiveSearching({ commit }, payload) {
    commit('setIsSearching', payload);
  },

  sortListOfProducts({ commit }, payload) {
    commit('setPriceSorting', payload);
  },

  // payload should contain object with key and value
  addFilter({ commit }, payload) {
    commit('addProductFilter', payload);
  },

  // payload should be key
  removeFilter({ commit }, payload) {
    commit('removeProductFilter', payload);
  },

  clearFilters({ commit }) {
    commit('clearFilters');
  },

  clearSearchResult({ commit }) {
    commit('clear-search-result');
  },

  getBrands({ commit }, payload) {
    let brandsList = payload.map(obj => obj.brand);
    commit('setBrandsList', brandsList);
  },

  deleteQueryParam({ commit }, param) {
    commit('delete-query-param', param);
  },
  addToCart({ state, dispatch, getters }, { id, quantity = 1 }) {
    if (getters.in_cart_qty_by_id(id) == 0) {
      dispatch("addProductToCart", { product: id, quantity });
    } else {
      dispatch("editProductInCart", {
        productId: id,
        basket: state.cart.id,
        quantity: getters.in_cart_qty_by_id(id) + quantity,
      });
    }
  },

  changeCartItemQuantity({ state, dispatch }, { id, quantity = 1 }) {
    dispatch("editProductInCart", {
      productId: id,
      basket: state.cart.id,
      quantity: quantity,
    });
  },

  removeFromCart({ state, dispatch, getters }, id) {
    if (getters.in_cart_qty_by_id(id) == 1) {
      dispatch("deleteProductFromCart", { lineId: getters.line_id_by_product_id(id) });
    } else if (getters.in_cart_qty_by_id(id) > 1) {
      dispatch("editProductInCart", {
        productId: id,
        basket: state.cart.id,
        quantity: getters.in_cart_qty_by_id(id) - 1,
      });
    }
  },

  addProductToCart({ dispatch }, payload) {
    return marketplaceApi.post(`/api/basket/add-product/`, payload).then((res) => {
      if (res?.data) {
        dispatch("getUserCart");
      } else {
        throw Error('no user info in response');
      }
    });
  },

  editProductInCart({ dispatch, getters }, { productId, basket, quantity }) {
    let lineId = getters.line_id_by_product_id(productId);
    return marketplaceApi.put(`/api/basket/lines/${lineId}/`, { basket, quantity }).then((res) => {
      if (res?.data) {
        dispatch("getUserCart");
      } else {
        throw Error('no user info in response');
      }
    });
  },

  deleteProductFromCart({ dispatch }, { lineId }) {
    return marketplaceApi.delete(`/api/basket/lines/${lineId}/`).then(() => {
      dispatch("getUserCart");
    });
  },

  checkoutOrder({}, payload) { // eslint-disable-line
    return marketplaceApi.post(`/api/checkout/`, payload).then((res) => {
      if (res?.data) {
        return res.data;
      } else {
        throw Error('no user info in response');
      }
    });
  },

  sendFeedback({}, { productId, feedbackData }) { // eslint-disable-line
    const url = `/api/products/${productId}/reviews/`;
    if (feedbackData.body) {
      return marketplaceApi.post(url, feedbackData).then((res) => {
        if (res?.data) {
          return res.data;
        } else {
          throw Error('no user info in response');
        }
      });
    } else {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      return marketplaceApi.post(url, feedbackData, config).then((res) => {
        if (res?.data) {
          return res.data;
        } else {
          throw Error('no user info in response');
        }
      });
    }
  },

  addToCancelQueue({ commit }, { is_editing, lineId }) {
    commit('add-to-cancel-queue', { is_editing, lineId });
  },

  addAllToCancelQueue({ state, commit }, { is_editing, lineIds }) {
    if (state.cancelQueue.length === lineIds.length) {
      commit('clear-cancel-queue');
    } else {
      commit('add-all-to-cancel-queue', { is_editing, lineIds });
    }
  },

  clearCancelQueue({ commit }) {
    commit('clear-cancel-queue');
  },

  cancelOrderLines({ state, dispatch }, orderId) {
    return marketplaceApi.patch(`/api/orders/${orderId}/`, { cancelled_lines: state.cancelQueue }).then((res) => {
      if (res?.data) {
        dispatch("clearCancelQueue");
        return res.data;
      } else {
        throw Error('no user info in response');
      }
    });
  },
};

const getters = {
  // Cart getters
  'cart_price_total': (state) => {
    return state.cart.total_excl_tax;
  },

  'cart_qty_total': (state) => {
    return state.cart_products?.reduce((acc, cur) => {
      return acc + cur.quantity || 0;
    }, 0);
  },

  'in_cart_qty_by_id': (state) => (id) => {
    return state.cart_products.find(item => item.product.id === id)?.quantity || 0;
  },

  'line_id_by_product_id': (state) => (id) => {
    return state.cart_products.find(item => item.product.id === id)?.id || 0;
  },

  // Permission getters
  'user__can_order': (state) => {
    return state.permsList?.includes('user.can_order');
  },
  'order__can_approve': (state) => {
    return state.permsList?.includes('order.can_approve');
  },
  'order__can_accept': (state) => {
    return state.permsList?.includes('order.can_accept');
  },

};

export const marketplace = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
