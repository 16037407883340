import { setCustomBackArrowMode, setCustomBackArrowVisible } from '@/helpers/bridge';
import { isNaN, isNil, isNumber, isString } from '@/utils/type-checks';

export const generateWatermarkImageUri = (watermark) => {
  // watermark = 'E0D_I6F2H041F.D9B2F E1N1H Hbvcv'; //example
  console.log('watermark:', watermark);
  let svgVal = `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='320px' width='240px' viewBox="0 0 240 320"> 
    <style type='text/css'> 
    text{
      font-family: Inter, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-style: normal;
      font-size: 16px; 
      font-weight: 400; 
      line-height: 24px; 
      letter-spacing: 0.02em;
    }
    </style> 
    <text 
      x='120' 
      y='120' 
      dominant-baseline='middle' 
      text-anchor='middle' 
      transform='rotate(-45, 120, 120)' 
      fill='#D9E3ED'
      textLength="90%" 
      lengthAdjust="spacingAndGlyphs"
    >${ watermark }</text> 
    <path 
      fill='none' 
      stroke='#D9E3ED'
      stroke-width='1' 
      d='M0,240 l40,40 M40,40 l40,40' 
    /> 
    <text 
      x='100' 
      y='220' 
      dominant-baseline='middle' 
      text-anchor='middle' 
      transform='rotate(-45, 120, 180)' 
      fill='#D9E3ED'
      textLength="90%" 
      lengthAdjust="spacingAndGlyphs"
    >${ watermark }</text> 
  </svg>`;
  return `url("data:image/svg+xml,${encodeURIComponent(svgVal)}")`;
};
// DDE1E5DF
export const handleExternalNavigation = async (link, options = {}) => {
  try {
    await setCustomBackArrowVisible(false);
    await setCustomBackArrowMode(false);
  } finally {
    if (options.cookie){
      document.cookie = options.cookie;
      window.location.href = link;
    } else {
      window.location.assign(link);
    }
  }
};

export const serverFormatedDate = (date) => {
  return ('0'+date.getDate()).slice(-2)
    +'.'+('0'+(date.getMonth()+1)).slice(-2)
    +'.'+date.getFullYear();
};

export const getYouTubeID = (url = '') => {
  let arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return arr[2] !== undefined ? arr[2].split(/[^0-9a-z_\-]/i)[0] : arr[0] //eslint-disable-line
};


export function toNumber(val) {
  return val * 1;
}

export function numOr0(number, throwError = false) {
  if (isNil(number)) return 0;
  if (isNumber(number)) return number;

  if (isString(number)) {
    let tmpNumber = toNumber(number);

    if (isNaN(tmpNumber)) {
      if (throwError) throw new Error('Wrong number formatting');

      number = number.replace(/\s/g, '');
      number = number.replace(',', '.');
      number = toNumber(number);
      tmpNumber = isNaN(number) ? 0 : number;
    }

    if (throwError) throw new Error('Wrong number formatting');

    return tmpNumber;
  }

  // throw
  return 0;
}

export function toPascalCase(inputString) {
  const words = inputString.split(/[\s_\\-]/);
  const pascalCaseWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return pascalCaseWords.join('');
}

export function isIos() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /(iPad|iPhone|iPod)/i.test(userAgent);
}

export function iosAndMac() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /(Macintosh|iPad|iPhone|iPod)/i.test(userAgent);
}

export function debounce(func, wait) {
  let timeout;

  return function(...args) {
    const context = this;

    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}