import { LANG } from '@/config/constants';
import { getQueryLang } from '@/plugins/i18n';

const state = () => ({
  app_error: '',
  back_link_trigger_value: undefined,
  footer_is_shown: false,
  header_action: undefined,
  header_height: 0,
  header_title: '',
  footer_height: 0,
  is_select_auth_form: false,
  language: getQueryLang(LANG) || LANG.RU,
  my_calendar_modal: false,
  rotated_chart_component: undefined,
  theme_color: 'default',
});

const mutations = {
  'set_rotated_chart_component'(state, payload){
    state.rotated_chart_component = payload;
  },
  'set_my_calendar_modal'(state, payload){
    state.my_calendar_modal = payload;
  },
  'set_header_title'(state, payload){
    state.header_title = payload;
  },
  'set_header_action'(state, payload){
    state.header_action = payload;
  },
  'set_back_link_trigger_value'(state, payload){
    state.back_link_trigger_value = payload;
  },
  'set_app_error'(state, payload){
    state.app_error = payload;
  },
  'set_show_footer'(state, payload){
    state.footer_is_shown = !!payload;
  },
  'set_is_select_auth_form'(state, payload){
    state.is_select_auth_form = payload;
  },
  set_theme_color(state, color){
    state.theme_color = color || '#0086E7';
  },
  set_header_height(state, height){
    state.header_height = height;
  },
  set_footer_height(state, height){
    state.footer_height = height;
  },
};

const actions = {
  setRotatedChartComponent({ commit }, value){
    commit('set_rotated_chart_component', value);
  },
  clearRotatedChartComponent({ commit }){
    commit('set_rotated_chart_component', undefined);
  },
  showMyCalendarModal({ commit }){
    commit('set_my_calendar_modal', true);
  },
  hideMyCalendarModal({ commit }){
    commit('set_my_calendar_modal', false);
  },
  showFooter({ commit }){
    commit('set_show_footer', true);
  },
  hideFooter({ commit }){
    commit('set_show_footer', false);
  },
  alert({ commit }, value){
    if (typeof value === Error) {
      console.error(value);
    }
    commit('set_app_error', value);
  },
  setHeaderTitle({ commit }, value){
    commit('set_header_title', value);
  },
  clearHeaderTitle({ commit }){
    commit('set_header_title', "");
  },
  setHeaderAction({ commit }, value){
    commit('set_header_action', value);
  },
  clearHeaderAction({ commit }){
    commit('set_header_action', undefined);
  },
  setBackLinkTriggerValue({ commit, state }){
    commit('set_back_link_trigger_value', state.header_action);
  },
  setIsSelectAuthForm({commit}, val){
    commit('set_is_select_auth_form', val);
  },
  setThemeColor({ commit }, color){
    commit('set_theme_color', color);
  },
  setHeaderHeight({commit}, height){
    commit('set_header_height', height);
  },
  setFooterHeight({commit}, height){
    commit('set_footer_height', height);
  },

};
export const layout = {
  namespaced: true,
  state,
  mutations,
  actions,
};