import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

Vue.use(VueRouter);

const TheEmptyRouterView = () => import(/* webpackChunkName: "dev" */ '@/components/TheEmptyRouterView.vue');
const DevPage = () => import(/* webpackChunkName: "dev" */ '@/views/dev.vue');
const UnderMaintenancePage = () => import(/* webpackChunkName: "502" */ '@/views/marketplace/502.vue');
// mainPage
const Main = () => import(/* webpackChunkName: "mainPage" */ '@/views/main.vue');
const ModalNavigation = () => import(/* webpackChunkName: "mainPage" */ '@/components/Main/ModalNavigation.vue');
const MainHeader = () => import(/* webpackChunkName: "mainPage" */ '@/views/header/main.vue');
// navigationChild
const NavigationChild = () => import(/* webpackChunkName: "navigationChild" */ '@/views/navigationChild.vue');
const Form = () => import(/* webpackChunkName: "navigationChild" */ '@/views/form.vue');
// notifications
const Notifications = () => import(/* webpackChunkName: "notifications" */ '@/views/notifications/index.vue');
const NotificationsSettings = () => import(/* webpackChunkName: "notifications" */ '@/views/notifications/settings.vue');
// food
const FoodIndex = () => import(/* webpackChunkName: "food" */ '@/views/food/index.vue');
const FoodHeader = () => import(/* webpackChunkName: "food" */ '@/views/food/header.vue');
const FoodFooter = () => import(/* webpackChunkName: "food" */ '@/views/food/footer.vue');
const FoodHistoryIndex = () => import(/* webpackChunkName: "food" */ '@/views/food/history/index.vue');
const FoodHistoryById = () => import(/* webpackChunkName: "food" */ '@/views/food/history/_id/index.vue');
const FoodHistoryByIdHeader = () => import(/* webpackChunkName: "food" */ '@/views/food/history/_id/header.vue');
const FoodQr = () => import(/* webpackChunkName: "food" */ '@/views/food/qr/index.vue');
const FoodQrHeader = () => import(/* webpackChunkName: "food" */ '@/views/food/qr/header.vue');
const FoodOrder = () => import(/* webpackChunkName: "food" */ '@/views/food/order.vue');
// calendar
const Calendar = () => import(/* webpackChunkName: "calendar" */ '@/views/my_calendar_form/index.vue');
const CaledarFooter = () => import(/* webpackChunkName: "calendar" */ '@/views/my_calendar_form/footer.vue');
// way
const Way = () => import(/* webpackChunkName: "way" */ '@/views/way/index.vue');
const WayNewIssue = () => import(/* webpackChunkName: "way" */ '@/views/way/new_issue/index.vue');
const WayByCategoryById = () => import(/* webpackChunkName: "way" */ '@/views/way/_category/_id.vue');
// const WayArticleHeader = () => import(/* webpackChunkName: "way" */ '@/views/way/:category/header.vue');
const WayArticleFooter = () => import(/* webpackChunkName: "way" */ '@/views/way/_category/footer.vue');
// event
const Events = () => import(/* webpackChunkName: "way" */ '@/views/events/index.vue');
// pdf-reader
const PdfReader = () => import(/* webpackChunkName: "pdf" */ '@/views/pdf/index.vue');
const PdfReaderHeader = () => import(/* webpackChunkName: "pdf" */ '@/views/pdf/header.vue');
//my-video
const MyVideoMain = () => import(/* webpackChunkName: "myvideo" */ '@/views/my_video');
const Enterprises = () => import(/* webpackChunkName: "myvideo" */ '@/views/my_video/enterprises.vue');
const Manufactures = () => import(/* webpackChunkName: "myvideo" */ '@/views/my_video/manufactures.vue');
const CamerasByCategory = () => import(/* webpackChunkName: "myvideo" */ '@/views/my_video/cameras_by_category.vue');
const CamerasByManufacture = () => import(/* webpackChunkName: "myvideo" */ '@/views/my_video/cameras_by_manufacture.vue');
const CameraStream = () => import(/* webpackChunkName: "myvideo" */ '@/views/my_video/_stream/_id.vue');

const routes = [
  {
    path: '/',
    name: 'MainPage',
    components: {
      default: Main,
      header: MainHeader,
    },
    children:[
      {
        path: 'services',
        name: 'ModalServices',
        component: ModalNavigation,
      },
    ],
  },
  {
    path: '/m/:menu_id',
    name: 'NavigationItemChild',
    components: {
      default: NavigationChild,
    },
    props: {
      default: true,
    },
  },
  {
    path: '/m/:menu_id/s/my_calendar_form',
    name: 'NavigationItemCalendarFormPage',
    components: {
      default: Calendar,
      footer: CaledarFooter,
    },
    props: {
      default: route => ({
        activeDate: route.query.date,
        backToName: route.query.from,
      }),
      footer: true,
    },
  },
  {
    path: '/f/myvideo',
    name: 'MyVideo',
    components: {
      default: TheEmptyRouterView,
    },
    redirect: { name: 'MyVideoMain' },
    children: [
      {
        path: '',
        name: 'MyVideoMain',
        component: MyVideoMain,
        children: [
          {
            path: ':id/stream',
            name: 'CameraStream',
            component: CameraStream,
          },
        ],
      },
      {
        path: ':division_id/enterprises',
        name: 'Enterprises',
        component: Enterprises,
      },
      {
        path: ':division_id/enterprises/:enterprise_id/manufactures',
        name: 'Manufactures',
        component: Manufactures,
      },
      {
        path: ':division_id/enterprises/:enterprise_id/manufactures/:manufacture_id/cameras_by_manufacture',
        name: 'CamerasByManufacture',
        component: CamerasByManufacture,
        children: [
          {
            path: ':id/stream',
            name: 'ManufactureCameraStream',
            component: CameraStream,
          },
        ],
      },
      {
        path: ':category_id/cameras_by_category',
        name: 'CamerasByCategory',
        component: CamerasByCategory,
        children: [
          {
            path: ':id/stream',
            name: 'CategoryCameraStream',
            component: CameraStream,
          },
        ],
      },
    ],
  },
  {
    path: '/m/:menu_id/f/:form_name',
    name: 'NavigationItemFormPage',
    components: {
      default: Form,
    },
    props: {
      default: true,
    },
    beforeEnter(to, from, next) {
      let { form_name } = to.params;
      if (form_name === 'erg_way_form') {
        next({
          replace: true,
          name: 'Way',
        });
      } else if (form_name === 'my_calendar_form') {
        next({
          replace: true,
          name: 'NavigationItemCalendarFormPage',
          params: {
            menu_id: to.params.menu_id,
          },
        });
      } else if (form_name === 'market') {
        next({
          replace: true,
          name: 'Marketplace',
        });
      } else next();
    },
  },
  {
    path: '/s/erg_way_form',
    name: 'Way',
    components: {
      default: Way,
    },
    props: {
      default: route => ({
        ...route.query,
        ...route.params,
      }),
    },
  },
  {
    path: '/s/erg_way_form/:category',
    name: 'WayCategory',
    components: {
      default: Way,
    },
    props: {
      default: route => ({
        ...route.query,
        ...route.params,
      }),
    },
  },
  {
    path: '/s/erg_way_form/:category/:id',
    name: 'WayCategoryArticleById',
    components: {
      default: WayByCategoryById,
      footer: WayArticleFooter,
    },
    beforeEnter: (to, from, next) => {
      if (to.params.category === 'issues') {
        import(/* webpackChunkName: "way" */ '@/views/way/_issue/_id.vue')
          .then(component => {
            to.matched[0].components.default = component.default;
            next();
          })
          .catch(error => {
            alert(error);
            console.error('Failed to load WayIssueById.vue:', error);
            next();
          });
      } else {
        to.matched[0].components.default = WayByCategoryById;
        next();
      }
    },
    props: {
      default: route => ({
        ...route.query,
        ...route.params,
      }),
      footer: true,
    },
  },
  {
    path:'/way/new_issue',
    name: 'WayNewIssue',
    components: {
      default: WayNewIssue,
    },
  },
  {
    path: '/f/url',
    name: 'FormPageByUrl',
    components: {
      default: Form,
    },
    beforeEnter(to, _from, next){
      if (to.query?.form_url?.includes('market')) {
        next({
          replace: true,
          path: to.query.form_url,
        });
      } else next();
    },
    props: {
      default: (route) => {
        console.log('route?.query', route?.query);
        // First `if` is edge case
        if (route?.query?.state) {
          let urlParam = new URLSearchParams(route?.query?.state);
          return {
            is_edge_case: true,
            form_url: urlParam.get('form_url'),
            form_url_data: {
              ...route.query,
            },
          };
        } else if (route?.query?.method) {
          // deconstruct query parameters
          const {
            form_url,
            method,
            ...form_url_data
          } = route?.query;
          return {
            form_url: form_url,
            form_url_method: method,
            form_url_data,
          };
        }
        return {
          form_url: route?.query?.form_url,
          backToName: route.query.from,
        };
      },
    },
  },
  {
    path: '/f/events',
    name: 'ServiceEvents',
    components: {
      default: Events,
    },
    props: {
      default: route => ({
        type: route.query.type,
        page: route.query.page,
      }),
    },
  },
  {
    path: '/s/my_calendar_form',
    name: 'CalendarFormPage',
    components: {
      default: Calendar,
      footer: CaledarFooter,
    },
    props: {
      default: route => ({
        activeDate: route.query.date,
        backToName: route.query.from,
        ...route.params,
      }),
    },
  },
  {
    path: '/s/market',
    name: 'Marketplace',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/index.vue'),
    },
  },
  {
    path: '/s/market/select-city',
    name: 'MarketplaceSelectCity',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/select_city.vue'),
    },
  },
  {
    path: '/s/market/stories/:id',
    name: 'MarketplaceStories',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/components/marketplace/stories/story_card.vue'),
    },
  },
  {
    path: '/s/market/categories',
    name: 'Market',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/market.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
  },
  {
    path: '/s/market/products/:productId',
    name: 'ProductById',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/product.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/product-applications',
    name: 'Applications',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/product_applications.vue'),
    },
  },
  {
    path: '/s/market/cart',
    name: 'Cart',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/cart.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
  },
  {
    path: '/s/market/limit-statement',
    name: 'LimitStatement',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/limit_statement.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
  },
  {
    path: '/s/market/wishlist',
    name: 'Wishlist',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/wishlist.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
  },
  {
    path: '/s/market/orders',
    name: 'Orders',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/orders.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/:id',
    name: 'OrderById',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/order_by_id.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/tracking/:line_id',
    name: 'OrderByIdTracking',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/order_tracking.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/approve-orders',
    name: 'ApproveOrders',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/approve_orders.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/approve-orders/:id',
    name: 'ApproveById',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/approve_by_id.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/accept-orders',
    name: 'AcceptOrders',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/accept_orders.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/market/orders/accept-order/:number',
    name: 'AcceptByNumber',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/accept_by_id.vue'),
      footer: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/under_maintenance',
    name: 'MarketUnderMaintenancePage',
    components: {
      default: UnderMaintenancePage,
    },
  },
  {
    path: '/s/market/discounts',
    name: 'Discounts',
    components: {
      default: () => import(/* webpackChunkName: "marketplace" */ '@/views/marketplace/discounts.vue'),
    },
  },
  { path: '/s/merch', redirect: '/s/merch/categories' },
  {
    path: '/s/merch/categories',
    name: 'Merch',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/categories.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
  },
  {
    path: '/s/merch/list-of-products/:categoryId',
    name: 'Merch List of products',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/list_of_products.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/merch/products/:productId',
    name: 'MerchProductById',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/product.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/merch/cart',
    name: 'MerchCart',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/cart.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
  },
  {
    path: '/s/merch/orders',
    name: 'MerchOrders',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/orders.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/merch/orders/:id',
    name: 'MerchOrderById',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/order_by_id.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
    props: {
      default: true,
    },
  },
  {
    path: '/s/merch/support',
    name: 'MerchSupport',
    components: {
      default: () => import(/* webpackChunkName: "merch" */ '@/views/merch/support.vue'),
      footer: () => import(/* webpackChunkName: "merch" */ '@/views/merch/footer.vue'),
    },
  },
  {
    path: '/s/merch/under_maintenance',
    name: 'MerchUnderMaintenancePage',
    components: {
      default: UnderMaintenancePage,
    },
  },
  {
    path: '/f/:form_name',
    name: 'FormPage',
    components: {
      default: Form,
    },
    props: {
      default: route => ({
        backToName: route.query.from,
        ...route.params,
      }),
    },
    beforeEnter(to, from, next) {
      let { form_name } = to.params;
      if (to.params.form_name === 'erg_way_form') {
        next({
          replace: true,
          name: 'Way',
        });
      } else if (to.params.form_name === 'my_calendar_form') {
        next({
          replace: true,
          name: 'CalendarFormPage',
        });
      } else if (form_name === 'market') {
        next({
          replace: true,
          name: 'Marketplace',
        });
      } else next();
    },
  },
  {
    path: '/n',
    name: 'NotificationsPage',
    components: {
      default: Notifications,
    },
  },
  {
    path: '/n/settings',
    name: 'NotificationsSettingsPage',
    components: {
      default: NotificationsSettings,
    },
  },
  {
    path: '/food',
    name: 'FoodPage',
    components: {
      default: FoodIndex,
      header: FoodHeader,
      footer: FoodFooter,
    },
  },
  {
    path: '/food/history',
    name: 'FoodHistoryPage',
    components: {
      default: FoodHistoryIndex,
      header: FoodHeader,
    },
  },
  {
    path: '/food/history/:id',
    name: 'FoodHistoryByIdPage',
    components: {
      default: FoodHistoryById,
      header: FoodHistoryByIdHeader,
    },
  },
  {
    path: '/food/qr',
    name: 'FoodQrPage',
    components: {
      default: FoodQr,
      header: FoodQrHeader,
    },
  },
  {
    path: '/food/order',
    name: 'FoodOrderPage',
    components: {
      default: FoodOrder,
      header: FoodHeader,
    },
  },
  {
    path: '/pdf',
    name: 'PdfPage',
    components: {
      default: PdfReader,
      header: PdfReaderHeader,
    },
    props: {
      header: true,
      default: route => ({
        ...route.query,
        ...route.params,
      }),
    },
  },
  {
    path: '/dev_page',
    name: 'DevPage',
    components: {
      default: DevPage,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.name === 'MainPage') {
    if(document.body.style.overflow === "hidden")document.body.style.overflow = "";
  }
  if(store?.getters['mainPage/is_open_stories']){
    store?.dispatch('mainPage/setIsOpenStories', false);
  }
  next();
});


export default router;
